import client from './client'

const baseURL = '/access/reports'

export type Period = '7day' | '30day' | '90day' | '365day'
export type PeriodType = 'this' | 'last'

export interface Score {
  label:          string;
  value:          number;
  previous_value: number;
  breakdown?:     Partial<Score>[];
}

export interface XYConfig {
  title:    string;
  scales:   Scale[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  data:  number[];
}

export interface Scale {
  type:   'x' | 'y';
  title?: string;
  labels?: string[];
}


export function getReportOverview(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/overview/${periodType}/${period}`
  return client.get<Score[]>(url)
}

export function getOverallPerformance(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/performance/${periodType}/${period}`
  return client.get<XYConfig>(url)
}