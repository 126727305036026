<script lang="ts" setup>
import { Counter } from '@dailycare-au/visualise'
import { SkeletonLoader, Skeleton, Alert, AlertDescription } from '@dailycare-au/ui'
import { asyncComputed } from '@vueuse/core'
import { ref } from 'vue'
import { organisationStore } from '@/stores/organisation'
import { storeToRefs } from 'pinia'
import { getReportOverview } from '@/api/reports.api'
import { useReportFilters } from './filters'

const { organisation } = storeToRefs(organisationStore())
const { period } = useReportFilters()

const loading = ref(false)
const overview = asyncComputed(async () => {
  const { data } = await getReportOverview(organisation.value!.id, period.value, 'this')
  return data
}, [], { evaluating: loading })
</script>

<template>
  <SkeletonLoader :count="4" :loading="loading">
    <Counter
      v-for="item in overview"
      :key="item.label"
      :value="item.value"
      :title="item.label"
      :prev="item.previous_value"
      :period="period"
    >
      <Alert v-for="bi of item.breakdown?.slice(0, 2)" :key="bi.label" size="sm">
        <AlertDescription class="text-xs">
          <span v-if="bi.value" class="font-bold mr-1">{{ bi.value }}</span>
          {{ bi.label }}
        </AlertDescription>
      </Alert>
    </Counter>

    <template #skeleton>
      <Skeleton class="h-40 w-full rounded-xl" />
    </template>
  </SkeletonLoader>
</template>